import { useEffect } from 'react'
import { debounce } from 'lodash'

export function useDebounceResizeWindow(delay?: number) {
  const time = delay || 1500

  useEffect(() => {
    const handleResize = debounce(() => {}, time)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })
}
