import React, { useEffect, useState } from 'react'
import cs from 'classnames'
import { UIContext, UIContextType } from './UIContext'
import { useRouter } from '../../hooks/useRouter'
import useHomeData from '../../hooks/useHomeData'

interface UIContextProviderProps {
  children: React.ReactNode | null
}

interface UIContextProviderState {
  isLoading: boolean
  isAnimating: boolean
}

let timeout: any = undefined
export const UIContextProvider = (props: UIContextProviderProps) => {
  const [state, setState] = useState<UIContextProviderState>({
    isLoading: true,
    isAnimating: true
  })
  const router = useRouter()
  const { loading: loadingData, data } = useHomeData()

  useEffect(() => {
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    if (state.isLoading) return

    if (router.pathname === '/') {
      timeout = setTimeout(() => {
        setState({ ...state, isAnimating: false })
      }, 3000)
    } else {
      setState({ ...state, isAnimating: false })
    }
  }, [state.isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!!window) {
      setState({ ...state, isLoading: false })
    }
  }, [global.window]) // eslint-disable-line react-hooks/exhaustive-deps

  const context: UIContextType = {
    isAnimating: state.isAnimating
  }
  if (state.isLoading || loadingData) {
    return <></>
  }
  return (
    <UIContext.Provider value={context}>
      <div
        className={cs(
          'HeroBanner HeroBanner--loader',
          state.isAnimating && 'HeroBanner--loader--active'
        )}
      >
        <div className="HeroBanner__content">
          <div className="HeroBanner__inner">
            <h3>{data?.pages?.nodes[0]?.homeOptions?.subtitle}</h3>
          </div>
        </div>
      </div>
      {props.children}
    </UIContext.Provider>
  )
}
