import React from 'react'
import { CityPanels } from '../../components/Home/CityPanels'

//styles
import './Home.scss'

export const Home = () => {
  return (
    <div className="Home">
      <CityPanels />
    </div>
  )
}
