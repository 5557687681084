import { useQuery } from '@apollo/react-hooks'
import {
  GET_HOME_DATA,
  HomeResponse,
  HomeInput,
  LanguageCodes
} from '../../graphql/queries/home'
import { getLocalValues } from '../helpers/localStorageHelpers'

const useHomeData = () => {
  const localValues = getLocalValues()

  const { loading, data, error } = useQuery<HomeResponse, HomeInput>(
    GET_HOME_DATA,
    {
      variables: {
        language: localValues.lang.toUpperCase() as LanguageCodes
      }
    }
  )

  return {
    loading,
    error,
    data
  }
}
export default useHomeData
