import React from 'react'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { ApolloProvider } from 'react-apollo'
import { createApolloClient } from './apollo/createApolloClient'
import { UIContextProvider } from './lib/context/UIContext/UIContextProvider'

import EN from './locales/en/strings.json'
import SQ from './locales/sq/strings.json'
import SR from './locales/sr/strings.json'
import { getLocalValues } from './lib/helpers/localStorageHelpers'

//Partials
import { Routes } from './routes/Routes'
import { Sidebar } from './components/Sidebar'

//Styles
import './App.scss'

const apolloClient = createApolloClient()

function App() {
  const localValues = getLocalValues()
  const resources = {
    en: {
      translation: EN
    },
    sq: {
      translation: SQ
    },
    sr: {
      translation: SR
    }
  }
  i18n.use(initReactI18next).init({
    resources,
    lng: localValues.lang || 'en',

    keySeparator: false,

    interpolation: {
      escapeValue: false
    }
  })

  return (
    <div className="App">
      <ApolloProvider client={apolloClient}>
        <UIContextProvider>
          <div className="App_wrapper">
            <Sidebar />
            <main className="Main">
              <Routes />
            </main>
          </div>
        </UIContextProvider>
      </ApolloProvider>
    </div>
  )
}

export default App
