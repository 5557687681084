import React from 'react'
import { Switch } from 'react-router-dom'

import { PublicRoute } from './PublicRoute'

import { Home } from '../pages/Home/Home'
import { Maker } from '../pages/Maker/Maker'
import { About } from '../pages/About/About'
import { Case } from '../pages/Case/Case'
import { Page404 } from '../pages/Page404/Page404'

export const Routes = () => {
  return (
    <Switch>
      <PublicRoute path={`/`} component={Home} exact />
      <PublicRoute path={`/about`} component={About} exact />
      <PublicRoute path={`/indeks`} component={Maker} exact />
      <PublicRoute path={`/case/:slug`} component={Case} exact />

      <PublicRoute component={Page404} />
    </Switch>
  )
}
