import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { HttpLink, ApolloLink, Operation, NextLink } from 'apollo-boost'

import introspectionQueryResultData from './fragmentTypes.json'
import { errorLink } from './errorLink.middleware'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const cache = new InMemoryCache({ fragmentMatcher })
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
}) as HttpLink

const middleware = new ApolloLink((operation: Operation, forward: NextLink) => {
  return forward(operation)
})

const afterware = new ApolloLink((operation: Operation, forward: NextLink) =>
  forward(operation).map((response) => {
    return response
  })
)

const link = ApolloLink.from([errorLink, middleware, afterware, httpLink])

export function createApolloClient() {
  return new ApolloClient({
    link: link,
    cache
  })
}
