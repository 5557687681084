import React from 'react'
import i18n from 'i18next'

import {
  getLocalValues,
  setLocalValue
} from '../../lib/helpers/localStorageHelpers'

const Locales = {
  sq: 'Shqip',
  en: 'English',
  sr: 'Srpski'
}

export const LanguageSelector = () => {
  const localValue = getLocalValues()
  const handleClick = (key: string) => {
    setLocalValue('lang', key)
    i18n.changeLanguage(key)
    const url = window.location.href.split('#')[0]
    window.location.href = url
  }
  return (
    <ul className="LanguageSelector">
      {Object.keys(Locales).map((locale) => {
        const isActive = locale === localValue.lang
        return (
          <li key={locale}>
            <span
              onClick={() => handleClick(locale)}
              style={isActive ? { color: '#ff0000' } : {}}
            >
              {Locales[locale as 'en' | 'sq' | 'sr'] || locale}
            </span>
          </li>
        )
      })}
    </ul>
  )
}
