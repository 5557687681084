import gql from 'graphql-tag'
import { LanguageCodes } from './home'

export interface SectionContent {
  leftContent: string
  rightContent: string
}

export interface Case {
  id: string
  databaseId: number
  slug: string
  title: string
  excerpt: string
  content: string
  featuredImage: {
    node: {
      id: string
      sourceUrl: string
    }
  }
  locations: {
    nodes: {
      id: string
      slug: string
      name: string
    }[]
  }
  municipalities: {
    nodes: {
      id: string
      slug: string
      name: string
    }[]
  }
  categories: {
    nodes: {
      id: string
      slug: string
      name: string
    }[]
  }
  sectionsContent: {
    video: {
      mediaItemUrl: string
    }
    date: string
    sectionsContent: SectionContent[]
  }
}

export interface CaseResponse {
  posts: {
    nodes: Case[]
  }
}
export interface CaseInput {
  name: string
  language: LanguageCodes
}

export const GET_CASE = gql`
  query SingleCase($name: String, $language: LanguageCodeFilterEnum) {
    posts(where: { name: $name, language: $language }) {
      nodes {
        id
        databaseId
        slug
        date
        excerpt
        content
        featuredImage {
          node {
            id
            sourceUrl
          }
        }
        categories(first: 1) {
          nodes {
            id
            slug
            name
          }
        }
        locations(first: 1) {
          nodes {
            id
            slug
            name
          }
        }
        municipalities(first: 1) {
          nodes {
            id
            slug
            name
          }
        }
        sectionsContent {
          video {
            mediaItemUrl
          }
          date
          sectionsContent {
            leftContent
            rightContent
          }
        }
      }
    }
  }
`
