import React from 'react'
import { Link } from 'react-router-dom'

//styles
import './Page404.scss'

export const Page404 = () => {
  return (
    <div className="Page404">
      <>
        <h1>404 - Page Not Found</h1>
        <Link to={`/`}>
          <span>Go back home</span>
        </Link>
      </>
    </div>
  )
}
