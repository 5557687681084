//
// WARNING
//
// Do not make manual changes to this file.
// This file was generated by generate-AllBusinessIcons-component.js.
//
//


import React from 'react';

/**
 * A list of all available icons in the icon set.
 */
export type IconDefinition = 'arrow-left' | 'logo' | 'wheelr';

export const availableIcons: IconDefinition[] = ['arrow-left', 'logo', 'wheelr'];

export const IconSvg = {
  'arrow-left': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.998 26.121"><g fill="none" stroke="#fff" stroke-width="3"><path stroke-miterlimit="10" d="M3.545 12.839l35.432.495" data-name="Line 3"/><path d="M14.121 25.06l-12-12 12-12" data-name="Path 2"/></g></svg>,
  'logo': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 228"><text fill="#fff" data-name="SITES — STORIES" transform="rotate(-90 130 98)"><tspan x="0" y="0">SITES — STORIES</tspan></text></svg>,
  'wheelr': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path fill="#e30613" d="M50 22.956H32.629l15.041-8.692-2.044-3.529-15.04 8.679 8.678-15.041-3.542-2.043-8.678 15.041V0h-4.088v17.371L14.264 2.33l-3.528 2.043 8.678 15.041-15.041-8.678-2.043 3.528 15.041 8.692H0v4.087h17.371L2.33 35.722l2.044 3.542 15.041-8.678-8.679 15.041 3.529 2.044 8.692-15.041V50h4.087V32.629l8.678 15.041 3.542-2.044-8.678-15.04 15.041 8.678 2.044-3.542-15.042-8.678H50z"/></svg>,
};
