import React, { useEffect } from 'react'
import cs from 'classnames'
import { useUIContext } from '../../lib/context/UIContext/UIContext'
import useHomeData from '../../lib/hooks/useHomeData'
import { HandleLoadingState } from '../HandleLoadingState'
import { SliderBanner } from './SliderBanner'
import setupTypewriter from '../../lib/helpers/setupTypewriter'
import { useWindowWidth } from '../../lib/hooks/useWindowWidth'
import { Icon } from '../shared/Icon/Icon'

export const HeroBanner = () => {
  const { isAnimating } = useUIContext()
  const { loading, data } = useHomeData()
  const width = useWindowWidth()
  const typer = document.getElementById('HeroBanner__content--dsc')
  const isMobile = width <= 992

  useEffect(() => {
    if (!typer) return
    const typewriter = setupTypewriter(typer)

    typewriter.type()
  }, [typer])

  return (
    <HandleLoadingState loading={loading}>
      <div className="HeroBanner">
        {!isMobile ? (
          <>
            <div className="HeroBanner__content">
              <div className="HeroBanner__inner">
                <div>
                  <h2 className={cs(isAnimating && 'hide-from-animation')}>
                    {data?.pages?.nodes[0]?.homeOptions?.title}
                  </h2>
                  <h3>{data?.pages?.nodes[0]?.homeOptions?.subtitle}</h3>
                </div>
                <div className="HeroBanner__content--dsc">
                  <p className={cs(isAnimating && 'hide-from-animation')}>
                    {data?.pages?.nodes[0]?.homeOptions?.description}
                  </p>
                </div>
              </div>
              <SliderBanner />
            </div>
            <h5>{data?.pages?.nodes[0]?.homeOptions?.info}</h5>
          </>
        ) : (
          <HeroBannerMobile>
            <h2
              className={cs(
                'HeroBannerMobile-title',
                isAnimating && 'hide-from-animation'
              )}
            >
              {data?.pages?.nodes[0]?.homeOptions?.title}
            </h2>

            <h3 className="HeroBannerMobile-subtitle">
              {data?.pages?.nodes[0]?.homeOptions?.subtitle}
            </h3>

            <SliderBanner />
          </HeroBannerMobile>
        )}
      </div>
    </HandleLoadingState>
  )
}

function HeroBannerMobile(props: any) {
  const handleOnClick = () => {
    const yOffset = 60
    const element = document.getElementById('HeroDescriptionMobile')

    if (!element) return
    const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset
    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  return (
    <div className="HeroBannerMobile">
      <div className="HeroBannerMobile-content">{props.children}</div>

      <div
        role="button"
        className="HeroBannerMobile-scroll"
        onClick={handleOnClick}
      >
        <Icon icon="arrow-left" />
      </div>
    </div>
  )
}
