import React from 'react'
import cs from 'classnames'
import { Link } from 'react-router-dom'

import { Case } from '../../../graphql/queries/home'
import useTranslation from '../../../lib/hooks/useTranslation'

export interface CaseBoxProps {
  caseBox: Case
  className?: string
}

export const CaseBox = ({ className, caseBox }: CaseBoxProps) => {
  const { t } = useTranslation()

  return (
    <div className={cs('CaseBox', className)}>
      <Link to={`/case/${caseBox?.slug}`}>
        <div className="CaseBox-title">
          <span className="CaseBox-dot">●</span>
          <h2>{caseBox?.locations.nodes[0]?.name}</h2>
        </div>
        <div className="CaseBox-content">
          <img
            src={caseBox?.featuredImage?.node?.sourceUrl}
            alt={`${caseBox?.categories?.nodes[0]?.name}-${caseBox?.locations?.nodes[0]?.name}`}
          />
          <span>{t('municipality')}</span>
          <h4>{caseBox?.municipalities.nodes[0]?.name}</h4>
        </div>
      </Link>
    </div>
  )
}
