import React from 'react'
import cs from 'classnames'
import { Link } from 'react-router-dom'

import Slick from 'react-slick'
import useHomeData from '../../lib/hooks/useHomeData'
import { FeatureCase } from '../../graphql/queries/home'
import { useUIContext } from '../../lib/context/UIContext/UIContext'

export const SliderBanner = () => {
  const { isAnimating } = useUIContext()
  const { data } = useHomeData()

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'ease',
    fade: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <div className={cs('SliderBanner', isAnimating && 'hide-from-animation')}>
      <Slick {...settings}>
        {data?.pages?.nodes[0]?.homeOptions?.featuredCases?.map(
          (featureCase: FeatureCase) => {
            return (
              <div key={`featured-case-${featureCase.id}`}>
                <Link to={`/case/${featureCase.slug}`}>
                  <div className="SliderBanner_item">
                    <div className="SliderBanner_item--thumb">
                      <img
                        src={featureCase.featuredImage.node.mediaItemUrl}
                        alt={featureCase.title}
                      />
                    </div>
                    <div className="SliderBanner_item--dsc">
                      <h3>
                        <span>● </span>
                        {featureCase.title}
                      </h3>
                    </div>
                  </div>
                </Link>
              </div>
            )
          }
        )}
      </Slick>
    </div>
  )
}
