import React, { useEffect, useState } from 'react'
import cs from 'classnames'
import { Icon } from '../components/shared/Icon/Icon'
import { useQuery } from '@apollo/react-hooks'
import {
  CitiesInput,
  CitiesResponse,
  City,
  GET_CITIES,
  LanguageCodes
} from '../graphql/queries/home'
import { HandleLoadingState } from '../components/HandleLoadingState'
import { useUIContext } from '../lib/context/UIContext/UIContext'
import { LanguageSelector } from '../components/LanguageSelector/LanguageSelector'
import { useRouter } from '../lib/hooks/useRouter'
import useTranslation from '../lib/hooks/useTranslation'
import { getLocalValues } from '../lib/helpers/localStorageHelpers'
import { Link } from 'react-router-dom'
import { useWindowWidth } from '../lib/hooks/useWindowWidth'

export const Sidebar = () => {
  const { isAnimating } = useUIContext()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const router = useRouter()
  const { t } = useTranslation()
  const localValues = getLocalValues()
  const width = useWindowWidth();


  const canGoBack =
    router.location.pathname.includes('/case/') ||
    router.location.pathname === '/indeks'
  const isDark = router.location.pathname === '/about'

  const cities = useQuery<CitiesResponse, CitiesInput>(GET_CITIES, {
    variables: {
      language: localValues.lang.toUpperCase() as LanguageCodes
    }
  })

  useEffect(() => {
    setSidebarOpen(false)
  }, [router])

  const handleLinkClick = (url: string) => {
    setSidebarOpen(false)
    router.push(url)
  }

  const handleBackPress = () => {
    router.history.goBack()
  }

  const handleMenuClick = (id: string, index: number) => {
    setSidebarOpen(false)
    if (router.location.pathname === '/') {
      const sectionHolder = document.querySelector('.Main')
      if(!id) return;
      // @ts-ignore
      const top = document.getElementById(id)?.offsetTop; // prettier-ignore
      // @ts-ignore
      top && sectionHolder.scroll({ top: top - 45 * index, behavior: 'smooth' })
    }
    router.push(`/#${id}`)
    if (router.location.pathname === '/' && width <= 992) {
      window.location.reload();
    }
  }

  const handleLogoClick = () => {
    router.push('/');
    window.location.reload();
  }

  return (
    <div className={cs('Sidebar ', isAnimating && 'hide-from-animation')}>
      <div
        className={cs(
          'Sidebar_left',
          isDark && 'Sidebar--dark',
          sidebarOpen && 'Sidebar--open'
        )}
      >
        {canGoBack ? (
          <div className="Sidebar__goback" onClick={handleBackPress}>
            <Icon icon="arrow-left" />
          </div>
        ) : (
          <div
            className={cs('Hamburger', sidebarOpen && 'Hamburger--toggled')}
            onClick={() => {
              setSidebarOpen(!sidebarOpen)
            }}
          >
            <span></span>
          </div>
        )}

        <div className="Sidebar__Logo">
          <span onClick={handleLogoClick}>SITES — STORIES</span>
        </div>

        <Link to="/indeks" className="Icon__wheelr Icon--spin-hover">
          <span>{t('index')}</span>
        </Link>
      </div>
      <div className={cs('Sidebar_wrapper', sidebarOpen && 'Sidebar--open')}>
        <HandleLoadingState loading={cities.loading}>
          <div className="Sidebar_wrapper__cities">
            <ul>
              {cities?.data?.categories.nodes?.map(
                (city: City, index: number) => {
                  return (
                    <li
                      key={`menu-item-${city.categoryId}`}
                      onClick={() => {
                        handleMenuClick(`city-${city.slug}`, index)
                      }}
                    >
                      <span>0{index + 1}</span>
                      {city.name}
                    </li>
                  )
                }
              )}
            </ul>
          </div>
        </HandleLoadingState>

        <div className="Sidebar_wrapper__bottom">
          <ul>
            <li onClick={() => handleLinkClick('/#zero')}>{t('overview')}</li>
            <li onClick={() => handleLinkClick('indeks')}>{t('maker')}</li>
            <li onClick={() => handleLinkClick('about')}>{t('about')}</li>
            <li>
              <LanguageSelector />
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
