import React from 'react'
import cs from 'classnames'
import { Link } from 'react-router-dom'

import { Case } from '../../../graphql/queries/home'

export interface CaseListItemProps {
  item: Case
  className?: string
}

export const CaseListItem = ({ className, item }: CaseListItemProps) => {
  return (
    <Link to={`/case/${item?.slug}`}>
      <div className={cs('CateListItem', className)}>
        <img
          src={item?.featuredImage.node.sourceUrl}
          alt={`${item?.categories.nodes[0]?.name}-${item?.locations.nodes[0]?.name}`}
        />
        <h3>{item?.locations.nodes[0]?.name}</h3>
        <h5>{item?.municipalities.nodes[0]?.name}</h5>
      </div>
    </Link>
  )
}
