import React from 'react'
import { QueryResult } from '@apollo/react-common'
import {
  Case,
  CasesInput,
  CasesResponse,
  CitiesInput,
  CitiesResponse,
  City
} from '../../graphql/queries/home'
import { CaseListItem } from '../shared/CaseListItem/CaseListItem'

interface Props {
  cities: QueryResult<CitiesResponse, CitiesInput>
  cases: QueryResult<CasesResponse, CasesInput>
}

export const MarkerCities = ({ cities, cases }: Props) => {
  return (
    <div className="MarkerCities">
      {cities.data?.categories.nodes.map((category: City, index: number) => {
        return (
          <div
            id={`marker-${category.slug}`}
            className="MarkerCity"
            key={`marker-city-${category.id}-${index}`}
          >
            <h4 className="MarkerCities__City">{category.name}</h4>
            <div className="MarkerCities__items">
              {cases.data?.posts.nodes?.map((item: Case) => {
                return (
                  category.name === item.categories.nodes[0].name && (
                    <CaseListItem key={`case-${item.databaseId}`} item={item} />
                  )
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}
