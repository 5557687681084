import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { HandleLoadingState } from '../../components/HandleLoadingState'
import { MarkerCities } from '../../components/Marker/MarkerCities'
import { MarkerHeader } from '../../components/Marker/MarkerHeader'
import {
  CasesInput,
  CasesResponse,
  CitiesInput,
  CitiesResponse,
  GET_CASES,
  GET_CITIES,
  LanguageCodes
} from '../../graphql/queries/home'
import { getLocalValues } from '../../lib/helpers/localStorageHelpers'

export const Maker = () => {
  const localValues = getLocalValues()
  const cities = useQuery<CitiesResponse, CitiesInput>(GET_CITIES, {
    variables: {
      language: localValues.lang.toUpperCase() as LanguageCodes
    }
  })
  const cases = useQuery<CasesResponse, CasesInput>(GET_CASES, {
    variables: {
      language: localValues.lang.toUpperCase() as LanguageCodes
    }
  })
  return (
    <div className="Marker">
      <MarkerHeader cities={cities} />
      <HandleLoadingState loading={cities.loading || cases.loading}>
        <MarkerCities cities={cities} cases={cases} />
      </HandleLoadingState>
    </div>
  )
}
