// @ts-nocheck
let timeoutTW: any = undefined
const setupTypewriter = (t) => {
  var HTML = t.innerHTML

  t.innerHTML = ''

  var cursorPosition = 0,
    tag = '',
    writingTag = false,
    tagOpen = false,
    typeSpeed = 10,
    tempTypeSpeed = 0

  var type = function () {
    if (writingTag === true) {
      tag += HTML[cursorPosition]
    }

    if (HTML[cursorPosition] === '<') {
      tempTypeSpeed = 0
      if (tagOpen) {
        tagOpen = false
        writingTag = true
      } else {
        tag = ''
        tagOpen = true
        writingTag = true
        tag += HTML[cursorPosition]
      }
    }
    if (!writingTag && tagOpen) {
      //@ts-ignore
      tag.innerHTML += HTML[cursorPosition]
    }
    if (!writingTag && !tagOpen) {
      if (HTML[cursorPosition] === ' ') {
        tempTypeSpeed = 0
      } else {
        tempTypeSpeed = Math.random() * typeSpeed + 50
      }
      t.innerHTML += HTML[cursorPosition]
    }
    if (writingTag === true && HTML[cursorPosition] === '>') {
      tempTypeSpeed = Math.random() * typeSpeed + 50
      writingTag = false
      if (tagOpen) {
        var newSpan = document.createElement('span')
        t.appendChild(newSpan)
        newSpan.innerHTML = tag
        //@ts-ignore
        tag = newSpan.firstChild
      }
    }

    cursorPosition += 1
    if (cursorPosition < HTML.length - 1) {
      timeoutTW = setTimeout(type, tempTypeSpeed, () => {
        clearTimeout(timeoutTW)
      })
    }
  }

  return {
    type: type
  }
}

export default setupTypewriter
