import React, { useEffect, useState } from 'react'
import cs from 'classnames'
import Slider from 'react-slick'

import { HeroBanner } from './HeroBanner'
import { CaseBox } from '../shared/CaseBox/CaseBox'
import { useQuery } from '@apollo/react-hooks'
import {
  Case,
  CasesInput,
  CasesResponse,
  CitiesInput,
  CitiesResponse,
  City,
  GET_CASES,
  GET_CITIES,
  LanguageCodes
} from '../../graphql/queries/home'
import { HandleLoadingState } from '../HandleLoadingState'
import { useUIContext } from '../../lib/context/UIContext/UIContext'
import { getLocalValues } from '../../lib/helpers/localStorageHelpers'
import { HomeContainer } from './HomeContainer'
import { useWindowWidth } from '../../lib/hooks/useWindowWidth'
import { HeroDescriptionMobile } from './HeroDescriptionMobile'

export const CityPanels = React.memo(
  () => {
    const [activeSlide, setActiveSlide] = useState<any>({})
    const width = useWindowWidth()
    const { isAnimating } = useUIContext()
    const localValues = getLocalValues()
    const cities = useQuery<CitiesResponse, CitiesInput>(GET_CITIES, {
      variables: {
        language: localValues.lang.toUpperCase() as LanguageCodes
      }
    })
    const cases = useQuery<CasesResponse, CasesInput>(GET_CASES, {
      variables: {
        language: localValues.lang.toUpperCase() as LanguageCodes
      }
    })

    const isMobile = width <= 992;

    useEffect(() => {
      function appHeight() {
        const doc = document.documentElement
        doc.style.setProperty('--vh', (window.innerHeight*.01) + 'px');
      }
    
      window.addEventListener('resize', appHeight);
      appHeight();
      return () => {
        window.removeEventListener('resize', appHeight);
      }
    }, [])

    return (
      <>
        <HandleLoadingState loading={cities.loading || cases.loading}>
          <HomeContainer cases={cases}>
            <section id="zero" className="HorizontalPanel description">
              <div
                className={cs(
                  'HeroBanner__outer HeroBanner__outer--mobile',
                  isAnimating && 'HeroBanner__outer--loading'
                )}
              >
                <HeroBanner />
              </div>
            </section>

            {isMobile && (
              <HeroDescriptionMobile
                isAnimating
                cities={cities.data?.categories?.nodes}
                cases={cases.data?.posts?.nodes}
              />
            )}

            {!isMobile &&
              cities.data?.categories.nodes.map(
                (category: City, index: number) => {
                  const items = cases.data?.posts?.nodes.filter(
                    (caseItem) =>
                      category.name === caseItem.categories.nodes[0].name
                  )
                  const numberOfItems = width > 1560 ? 3 : (width < 1560 && width > 1024 ? 2 : 1); //prettier-ignore
                  const slidesToShow = items && items?.length < numberOfItems ? items?.length : numberOfItems; //prettier-ignore
                  // const lastPage = items && (items.length % slidesToShow === 0 ? Math.floor((items.length / slidesToShow) + 0.01) : (Math.floor((items.length / slidesToShow) + 1.01) )); //prettier-ignore

                  return (
                    <section
                      key={`city-${category.id}`}
                      id={`city-${category.slug}`}
                      className={cs(
                        'HorizontalPanel description is--bottom-pinned ',
                        isAnimating && 'show-after-animation'
                      )}
                    >
                      <a
                        href={`city-${category.slug}`}
                        data-index={index}
                        className="CitiesNav__item"
                      >
                        <h4>{category.name}</h4>
                        <span>{index < 10 ? `0${index + 1}` : index + 1}</span>
                      </a>
                      <div className="custom_row">
                        <div className="w-100">
                          <Slider
                            {...{
                              dots: false,
                              infinite: false,
                              arrows: true,
                              slidesToShow: slidesToShow,
                              slidesToScroll: slidesToShow,
                              responsive: [
                                {
                                  breakpoint: 1560,
                                  settings: {
                                    slidesToShow: slidesToShow,
                                    slidesToScroll: slidesToShow
                                  }
                                },
                                {
                                  breakpoint: 1024,
                                  settings: {
                                    slidesToShow: slidesToShow,
                                    slidesToScroll: slidesToShow
                                  }
                                }
                              ],
                              afterChange: (current: number) =>
                                setActiveSlide({
                                  ...activeSlide,
                                  [category.slug]: current
                                })
                            }}
                          >
                            {items?.map((caseItem: Case) => {
                              return (
                                <CaseBox
                                  key={`case-${caseItem.databaseId}`}
                                  className="column-6"
                                  caseBox={caseItem}
                                />
                              )
                            })}
                          </Slider>
                          {/* {lastPage !== 1 && (
                        <span className="slider_number">
                          {activeSlide[category.slug] || 1} / {lastPage}
                        </span>
                      )} */}
                        </div>
                      </div>
                    </section>
                  )
                }
              )}
          </HomeContainer>
        </HandleLoadingState>
      </>
    )
  },
  () => true
)
