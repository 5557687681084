import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { HandleLoadingState } from '../../components/HandleLoadingState'
import { LanguageCodes } from '../../graphql/queries/home'
import Plyr from 'plyr-react'
import 'plyr-react/dist/plyr.css'
import {
  CaseInput,
  CaseResponse,
  GET_CASE,
  SectionContent
} from '../../graphql/queries/single'
import useTranslation from '../../lib/hooks/useTranslation'
import { useRouter } from '../../lib/hooks/useRouter'
import { getLocalValues } from '../../lib/helpers/localStorageHelpers'

export const Case = () => {
  const localValues = getLocalValues()
  const { t } = useTranslation()
  const router = useRouter()
  const { slug } = router.query

  const caseItem = useQuery<CaseResponse, CaseInput>(GET_CASE, {
    variables: {
      name: Array.isArray(slug) ? slug[0] : slug,
      language: localValues.lang.toUpperCase() as LanguageCodes
    }
  })

  return (
    <div className="CaseSingle animate-case-single">
      <HandleLoadingState loading={caseItem.loading}>
        <div className="CaseSingle__left">
          <section id="zero" className="HorizontalPanel single-section">
            <div className="single-section-item CaseSingle-left ">
              <div className="single-section-item-head case-animate-title">
                <span>{t('region')}</span>
                <p>
                  {caseItem.data?.posts?.nodes[0]?.categories?.nodes[0]?.name}
                </p>
                <span>{t('municipality')}</span>
                <p>
                  {
                    caseItem.data?.posts?.nodes[0]?.municipalities?.nodes[0]
                      ?.name
                  }
                </p>
                <span>{t('location-single')}</span>
                <h2>
                  {caseItem.data?.posts?.nodes[0]?.locations?.nodes[0]?.name}
                </h2>
                <span>{t('date')}</span>
                <p>{caseItem.data?.posts?.nodes[0]?.sectionsContent.date}</p>
              </div>
              <div
                className="case-animate-description"
                dangerouslySetInnerHTML={{
                  __html: caseItem.data?.posts?.nodes[0]?.content || ''
                }}
              />
            </div>
          </section>
          {caseItem.data?.posts?.nodes[0]?.sectionsContent?.sectionsContent?.map(
            (sectionContent: SectionContent, index: number) => {
              return (
                <section
                  key={`sectionContent-${index}`}
                  id={`sectionContent-${index}`}
                  className="HorizontalPanel single-section"
                >
                  <div className="single-section-item">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sectionContent.leftContent
                      }}
                    ></div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sectionContent.rightContent
                      }}
                    ></div>
                  </div>
                </section>
              )
            }
          )}
        </div>
        <div className="CaseSingle__right">
          <div className="case-single-image case-animate-image">
            {caseItem.data?.posts?.nodes[0]?.sectionsContent?.video
              ?.mediaItemUrl ? (
              <Plyr
                source={{
                  type: 'video',
                  sources: [
                    {
                      src:
                        caseItem.data?.posts?.nodes[0].sectionsContent.video
                          .mediaItemUrl,
                      provider: 'html5'
                    }
                  ]
                }}
                options={{
                  autoplay: false,
                  controls: ['play-large', 'play', 'progress']
                }}
              />
            ) : (
              <img
                src={
                  caseItem.data?.posts?.nodes[0].featuredImage.node.sourceUrl
                }
                alt={caseItem.data?.posts?.nodes[0].title || ''}
              />
            )}
          </div>
        </div>
      </HandleLoadingState>
    </div>
  )
}
