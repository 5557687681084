//@ts-nocheck
import React, { useEffect } from 'react'
import smoothScroll from 'smoothscroll-polyfill'
import { QueryResult } from '@apollo/react-common'
import { CasesInput, CasesResponse } from '../../graphql/queries/home'
import { useWindowWidth } from '../../lib/hooks/useWindowWidth'
import { useRouter } from '../../lib/hooks/useRouter'
// import { useUIContext } from '../../lib/context/UIContext/UIContext'
import { useDebounceResizeWindow } from '../../lib/hooks/useDebounceResizeWindow'

smoothScroll.polyfill()

interface Props {
  children: React.ReactNode
  cases: QueryResult<CasesResponse, CasesInput>
}

const getOffset = (el: Element) => {
  const rect = el.getBoundingClientRect()
  return {
    el,
    height: el.clientHeight,
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
    bottom: el.clientHeight + rect.top + window.scrollY
  }
}

const hasClass = (element, className) => {
  return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1
}

export const HomeContainer = React.memo(({ children, cases }: Props) => {
  useDebounceResizeWindow()

  const router = useRouter()
  const width = useWindowWidth()
  // const { isAnimating } = useUIContext()

  const isMobile = width <= 992
  const sectionHolder = isMobile ? window : document.querySelector('.Main')

  // scroll to specific section on load
  // useEffect(() => {
  //   if (isMobile && router.location.hash.includes('#city-')) {
  //     const el = document.getElementById(router.location.hash.replace('#', ''))
  //     const top = el && el.offsetTop
  //     const index = el.querySelector('.CitiesNav__item').getAttribute('data-index') // prettier-ignore
  //
  //     if (isAnimating && top) {
  //       setTimeout(() => {
  //         sectionHolder.scroll({
  //           top: top - (isMobile ? 146 : index * 45),
  //           behavior: 'smooth'
  //         })
  //       }, 3000)
  //     } else if (top) {
  //       sectionHolder.scroll({
  //         top: top - (isMobile ? 146 : index * 45),
  //         behavior: 'smooth'
  //       })
  //     }
  //   }
  // }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!cases.data || isMobile) return

    const sections = []
    let lastScrollTop = 0
    const homeSections = document.querySelectorAll('.HorizontalPanel')

    document.querySelectorAll('a.CitiesNav__item').forEach((btn, index) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault()
        const top = document.getElementById(btn.getAttribute("href"))?.offsetTop; // prettier-ignore
        top &&
          sectionHolder.scroll({
            top: top - (isMobile ? 18 : index * 45),
            behavior: 'smooth'
          })
        router.push(`/#${btn.getAttribute('href')}`)
      })
    })

    // if (isMobile) return

    homeSections.forEach(function (section) {
      sections.push(getOffset(section))
    })

    const onScroll = () => {
      const scrollTop = sectionHolder.scrollTop

      sections.forEach((section, index) => {
        // const button = section.el.querySelector('.CitiesNav__item')
        if (scrollTop > lastScrollTop) {
          // down direction
          if (
            scrollTop + (index - 1) * 45 > section.top && !hasClass(section.el, 'is--top-pinned') //prettier-ignore
          ) {
            section.el.classList.add('is--top-pinned')
            // button && router.push(`/#${button.getAttribute('href')}`)
          }
          if (
            scrollTop + (index - 1) * 45 + sections[0].height > section.top &&
            scrollTop + (index - 1) * 45 + sections[0].height <
              section.bottom &&
            hasClass(section.el, 'is--bottom-pinned')
          ) {
            section.el.classList.remove('is--bottom-pinned')
          }
        } else {
          // up direction
          if (
            scrollTop + (index - 1) * 45 < section.top &&
            hasClass(section.el, 'is--top-pinned')
          ) {
            section.el.classList.remove('is--top-pinned')
            // button && router.push(`/#${button.getAttribute('href')}`)
          }
          if (
            scrollTop  + section.height + sections[0].height  + (index - 1) * 45 < section.bottom && !hasClass(section.el, 'is--bottom-pinned') //prettier-ignore
          ) {
            section.el.classList.add('is--bottom-pinned')
          }
        }
      })

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
    }

    sectionHolder.addEventListener('scroll', onScroll)
  }, [cases]) // eslint-disable-line react-hooks/exhaustive-deps

  return <div className="container2">{children}</div>
})
