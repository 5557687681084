import React from 'react'
import { QueryResult } from '@apollo/react-common'
import { CitiesInput, CitiesResponse, City } from '../../graphql/queries/home'
import { useWindowWidth } from '../../lib/hooks/useWindowWidth'

interface Props {
  cities: QueryResult<CitiesResponse, CitiesInput>
}

export const MarkerHeader = ({ cities }: Props) => {
  const width = useWindowWidth();
  const sectionHolder = width < 768 ? window :  document.querySelector('.Main');

  const handleClick = (key: string) => {
    const top = document.getElementById(key)?.offsetTop; // prettier-ignore
    top && sectionHolder && sectionHolder.scroll({ top: top - 64, behavior: 'smooth' }) // prettier-ignore
  }

  return (
    <div className="MarkerHeader">
      <ul>
        {cities?.data?.categories.nodes.map((city: City, i: number) => (
          <li key={`${city.slug}-${i}`}>
            <a
              href={`#marker-${city.slug}`}
              onClick={(e) => {
                e.preventDefault()
                handleClick(`marker-${city.slug}`)
              }}
            >
              {city.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
