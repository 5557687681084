import gql from 'graphql-tag'
import { LanguageCodes } from './home'

export interface Page {
  id: string
  databaseId: number
  title: string
  content: string
  featuredImage: {
    node: {
      id: string
      sourceUrl: string
    }
  }
}
export interface PageAbout {
  id: string
  databaseId: number
  title: string
  content: string
  aboutOptions: {
    thankYouTitle: string
    thankYouDescription: string
    aboutTitle: string
    aboutDescription: string
    aboutShortDescription: string
    teamTitle: string
    team: {
      name: string
      description: string
    }[]
  }
}

export interface PageResponse {
  pages: {
    nodes: Page[]
  }
}
export interface PageAboutResponse {
  pages: {
    nodes: PageAbout[]
  }
}
export interface PageInput {
  name: string
  language: LanguageCodes
}

export const GET_PAGE = gql`
  query SinglePage($name: String, $language: LanguageCodeFilterEnum) {
    pages(where: { name: $name, language: $language }) {
      nodes {
        id
        databaseId
        title
        content
        featuredImage {
          node {
            id
            sourceUrl
          }
        }
      }
    }
  }
`

export const GET_PAGE_ABOUT = gql`
  query PageAbout($name: String, $language: LanguageCodeFilterEnum) {
    pages(where: { name: $name, language: $language }) {
      nodes {
        id
        databaseId
        title
        content
        aboutOptions {
          thankYouTitle
          thankYouDescription
          aboutTitle
          aboutDescription
          aboutShortDescription
          teamTitle
          team {
            name
            description
          }
        }
      }
    }
  }
`
