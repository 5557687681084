import { createContext, useContext } from 'react';

export interface UIContextType {
  isAnimating: boolean;
}

const UIContextValues: UIContextType = {
  isAnimating: true,
};

export const UIContext = createContext<UIContextType>(UIContextValues);

export const useUIContext = () => useContext(UIContext);
