import React, { useEffect, useState } from 'react'
import useHomeData from '../../lib/hooks/useHomeData'
import { Case, City } from '../../graphql/queries/home'
import cs from 'classnames'
import Slider from 'react-slick'
import { CaseBox } from '../shared/CaseBox/CaseBox'
import { useRouter } from '../../lib/hooks/useRouter'
import { useWindowWidth } from '../../lib/hooks/useWindowWidth'
import { Icon } from '../shared/Icon/Icon'

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop - 60)

export const HeroDescriptionMobile: React.FC<any> = (props) => {
  const { cities, cases } = props
  const router = useRouter()
  const width = useWindowWidth()
  const { data } = useHomeData()

  const casesRef = React.useRef(null)

  const [activeSlide, setActiveSlide] = useState<any>({})
  const [activeHref, setActiveHref] = useState<any>()
  const [activeCity, setActiveCity] = useState<any>()

  const scrollToCases = () => scrollToRef(casesRef)

  // on load
  useEffect(() => {
    const id = setTimeout(() => {
      if (
        !activeCity &&
        width <= 992 &&
        router.location.hash.includes('#city-')
      ) {
        const href = router.location.hash.replace('#', '')
        const target = document.getElementById(
          router.location.hash.replace('#', '')
        )
        const slug = target?.querySelector('a')?.getAttribute('data-slug')

        setActiveHref(href)
        setActiveCity(cities.find((c: any) => c.slug === slug))
        scrollToCases()
        document.body.style.overflow = 'hidden';
      }
    }, 1000)

    return () => clearTimeout(id)
  }, [router.location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!cities) return

    document.querySelectorAll('a.CitiesNav__item').forEach((btn, index) => {
      const btnHref = btn.getAttribute('href')
      const btnSlug = btn.getAttribute('data-slug')

      if (!btn || !btnHref) return

      btn.addEventListener('click', (e) => {
        e.preventDefault()
        setActiveHref(btnHref)
        setActiveCity(cities.find((c: any) => c.slug === btnSlug))

        document.body.style.overflow = 'hidden'

        const target = document.querySelector(`[data-targetid=${btnHref}]`)
        if (!target) return

        const y = target.getBoundingClientRect().top + window.pageYOffset - 60
        window.scrollTo({ top: y, behavior: 'smooth' })
        router.push(`/#${btn.getAttribute('href')}`)
      })
    })

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [cities, activeHref]) // eslint-disable-line react-hooks/exhaustive-deps

  const activeCityIndex =
    cities && activeCity ? cities.indexOf(activeCity) : null

  return (
    <>
      <div id="HeroDescriptionMobile" className="HeroDescriptionMobile">
        <div className="HeroDescriptionMobile-inner">
          <p>{data?.pages?.nodes[0]?.homeOptions?.description}</p>

          <h5>{data?.pages?.nodes[0]?.homeOptions?.info}</h5>
        </div>

        {!activeHref && (
          <div id="hdm-cities" className="HeroDescriptionMobile-cities">
            {cities?.map((category: City, index: number) => {
              return (
                <section
                  key={`city-${category.id}-${index}`}
                  id={`city-${category.slug}`}
                  className={cs(
                    'HorizontalPanel description is--bottom-pinned '
                  )}
                >
                  <a
                    href={`city-${category.slug}`}
                    data-index={index}
                    data-slug={category.slug}
                    className="CitiesNav__item CitiesNav__item-2"
                  >
                    <span> {category.name}</span>
                    <span>
                      {index || index === 0 ? (
                        <h5 className="CitiesNav__item-2__index">
                          {index < 10 ? `0${index + 1}` : index + 1}
                        </h5>
                      ) : (
                        <span>&nbsp;</span>
                      )}
                    </span>
                  </a>
                </section>
              )
            })}
          </div>
        )}
      </div>

      <div
        id="hdm-cases"
        ref={casesRef}
        className="HeroDescriptionMobile-cases"
      >
        {cities?.map((category: City, index: number) => {
          const items = cases?.filter(
            (caseItem: any) =>
              category.name === caseItem.categories.nodes[0].name
          )
          const numberOfItems = width > 1560 ? 3 : (width < 1560 && width > 1024 ? 2 : 1); //prettier-ignore
          const slidesToShow = items && items?.length < numberOfItems ? items?.length : numberOfItems; //prettier-ignore
          // const lastPage = items && (items.length % slidesToShow === 0 ? Math.floor((items.length / slidesToShow) + 0.01) : (Math.floor((items.length / slidesToShow) + 1.01) )); //prettier-ignore

          return (
            <div
              key={`city-${category.slug}`}
              data-targetid={`city-${category.slug}`}
              className={`custom-row ${
                activeHref === `city-${category.slug}`
                  ? 'custom_row--shown'
                  : 'custom_row--hidden'
              }`}
            >
              <div className="w-100">
                <div className="custom_row-header">
                  <span
                    role="button"
                    className="custom_row-back"
                    onClick={() => {
                      setActiveHref(undefined)
                      setActiveCity(undefined)
                      document.body.style.overflow = 'auto'
                    }}
                  >
                    <Icon icon="arrow-left" />
                  </span>

                  <h5 className="custom_row-title">{activeCity?.name}</h5>

                  {activeCityIndex || activeCityIndex === 0 ? (
                    <h5 className="custom_row-title">
                      {activeCityIndex < 10
                        ? `0${activeCityIndex + 1}`
                        : activeCityIndex + 1}
                    </h5>
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </div>

                <Slider
                  {...{
                    dots: false,
                    infinite: false,
                    arrows: true,
                    slidesToShow: slidesToShow,
                    slidesToScroll: slidesToShow,
                    responsive: [
                      {
                        breakpoint: 1560,
                        settings: {
                          slidesToShow: slidesToShow,
                          slidesToScroll: slidesToShow
                        }
                      },
                      {
                        breakpoint: 1024,
                        settings: {
                          slidesToShow: slidesToShow,
                          slidesToScroll: slidesToShow
                        }
                      }
                    ],
                    afterChange: (current: number) =>
                      setActiveSlide({
                        ...activeSlide,
                        [category.slug]: current
                      })
                  }}
                >
                  {items?.map((caseItem: Case) => {
                    return (
                      <CaseBox
                        key={`case-${caseItem.databaseId}`}
                        className="column-6"
                        caseBox={caseItem}
                      />
                    )
                  })}
                </Slider>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
