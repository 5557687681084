import gql from 'graphql-tag'

export type LanguageCodes = 'SQ' | 'EN' | 'RS'

export interface Case {
  id: string
  databaseId: number
  slug: string
  title: string
  excerpt: string
  featuredImage: {
    node: {
      id: string
      sourceUrl: string
    }
  }
  locations: {
    nodes: {
      id: string
      slug: string
      name: string
    }[]
  }
  categories: {
    nodes: {
      id: string
      slug: string
      name: string
    }[]
  }
  municipalities: {
    nodes: {
      id: string
      slug: string
      name: string
    }[]
  }
  sectionsContent: {
    date: string
  }
}
export interface CasesInput {
  language: LanguageCodes
}
export interface CasesResponse {
  posts: {
    nodes: Case[]
  }
}

export const GET_CASES = gql`
  query HomePosts($language: LanguageCodeFilterEnum) {
    posts(first: 1000, where: { language: $language }) {
      nodes {
        id
        databaseId
        slug
        title
        excerpt
        featuredImage {
          node {
            id
            sourceUrl(size: LARGE)
          }
        }
        locations(first: 1) {
          nodes {
            id
            slug
            name
          }
        }
        categories(first: 1) {
          nodes {
            id
            slug
            name
          }
        }
        municipalities(first: 1) {
          nodes {
            id
            slug
            name
          }
        }
        sectionsContent {
          date
        }
      }
    }
  }
`

export interface City {
  id: string
  categoryId: number
  slug: string
  name: string
  count: number
}
export interface CitiesInput {
  language: LanguageCodes
}
export interface CitiesResponse {
  categories: {
    nodes: City[]
  }
}

export const GET_CITIES = gql`
  query CitiesQuery($language: LanguageCodeFilterEnum) {
    categories(first: 100, where: { language: $language }) {
      nodes {
        id
        categoryId
        name
        slug
        count
      }
    }
  }
`

export interface FeatureCase {
  id: string
  slug: string
  title: string
  featuredImage: {
    node: {
      id: string
      mediaItemUrl: string
    }
  }
}

export interface HomeInput {
  language: LanguageCodes
}

export interface HomeResponse {
  pages: {
    nodes: {
      id: string
      homeOptions: {
        title: string
        subtitle: string
        description: string
        featuredCases: FeatureCase[]
        info: string
      }
    }[]
  }
}

export const GET_HOME_DATA = gql`
  query HomeData($language: LanguageCodeFilterEnum) {
    pages(where: { language: $language, search: "home" }) {
      nodes {
        id
        homeOptions {
          title
          description
          subtitle
          info
          featuredCases {
            ... on Post {
              id
              slug
              title
              featuredImage {
                node {
                  id
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    }
  }
`
