export type LocalStorageKeys = 'lang'

export interface LocalStorageValues {
  authToken: string
  refreshToken: string
  wooSession: string
  wishlist?: Array<number>
}

export const parseLocalStorageValues = () => {
  const localItems = localStorage.getItem('SITES_STORIES')

  if (localItems === null) {
    return {}
  }

  return JSON.parse(localItems)
}

export const getLocalValues = () => {
  const localValues = parseLocalStorageValues()

  return {
    lang: localValues?.lang || 'en'
  }
}

export const setLocalValue = (
  key: LocalStorageKeys,
  value: string | number[]
) => {
  const localValues = parseLocalStorageValues()

  const newValue = {
    ...localValues,
    [key]: value
  }

  localStorage.setItem('SITES_STORIES', JSON.stringify(newValue))
}

export const deleteLocalValue = (key: LocalStorageKeys) => {
  const localValues = parseLocalStorageValues()

  if (localValues[key]) {
    delete localValues[key]
  }

  if (localValues) {
    localStorage.setItem('SITES_STORIES', JSON.stringify(localValues))
  } else {
    localStorage.removeItem('SITES_STORIES')
  }
}
